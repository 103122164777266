
    export default {
        name: "GetStarted",
        props: {
            bg: {
                default: "primary",
                type: String
            },
            header: {
                default: true,
                type: Boolean
            },
            link: {
                default: true,
                type: Boolean
            }
        },
        data() {
            return {
                file: false,
                loading: false
            }
        },
        methods: {
            addImg(event) {
                if (event) this.storeFile(event.target.files[0]);
            },
            dropImg(event) {
                if (event && event.dataTransfer && event.dataTransfer.files) {
                    this.grabImg(event.dataTransfer.files);
                }
            },
            grabImg(files) {
                if (files.length) this.storeFile(files[0]);
            },
            sendFile() {
                this.get_csrf_token("uploadFile");
            },
            storeFile(file) {
                this.file = file;

                this.sendFile();
            },
            uploadFile() {
                this.loading = true;

                const data = new FormData();

                data.append("file", this.file);
                data.append("original_filename", this.file.name);
                data.append("title", this.file.name);
                data.append("type", this.file.type);

                this.$axios.post(`/api/assets/upload`, data, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                }).then(res => {
                    const { data } = res.data;

                    localStorage.setItem("uploaded-logo", data.location);
                    localStorage.setItem("uploaded-id", data.id);

                    this.$store.commit("builder/SET_FILE", data.location);
                    this.$store.commit("builder/SET_FILE_ID", data.id);

                    this.$router.push("/event-builder");
                }).catch(err => {
                    console.log(err);
                }).finally(() => {
                    this.loading = false;
                });
            }
        }
    }
